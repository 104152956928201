function Missed() {
  return (
    <div>
      <box className="DefaultBox">
        <p class="DefaultText">
          <br></br>Wil je meer weten over wat er aan komt? Schrijf je in op de nieuwsbrief, of volg ons op facebook of instagram! <br></br>
          <br></br>
          <br></br>2025
          <br></br>
          <br></br>
          <li>The quest for AI: een persoonlijk verhaal met wetenschappelijke diepgang, door Jan Lemeire</li>
          <li>Hoe kan je beter zoeken op internet, door Ben Vanachter</li>
          <li>3D printen, door Davy Cammermans, Daan Vandervelde & Willem Van Geyseghem, </li>
          <li>De ruimte: van oerknal tot aarde, door Krijn de Vries</li>
          <li>Darkweb experiment door We-IT</li>
          <br></br>
          <br></br>2024
          <br></br>
          <br></br>
          <li>Steamwork, VUB & We-IT - Dag van de Wetenschap voor de Druivenstreek</li>
          <li>Textiel bedrukken, Sophie Verbruggen</li>
          <li>Atomen en kernen, van kernenergie tot atoombom, door Krijn de Vries</li>
          <li>Workshop games programmeren met Pygame, door Jan Lemeire en Quentin Vandenschrik</li>
          <li>Drums met leds, door Jan Lemeire</li>
          <li>Spintronics, door Sophie Verbruggen</li>
          <li>Analoge signalen genereren van een dc bron, door Jonas Verbruggen</li>
          <li>Van aap tot beschaving, door Jan Lemeire</li>
          <li>Speciale relativiteitstheorie, door Krijn de Vries</li>
          <li>Burnout, door Chiara van Remmen</li>
          <li>Hoog performante teams en organisaties, door Willem Van Geyseghem </li>
          <li>Iedereen Felix - wetenschappelijk zoektocht, een samenwerking van Steamwork, VUB en We-IT</li>
          <li>Hoe gebruiken we de kleinste deeltjes om de grootste objecten in ons universum te bestuderen?, door Krijn de Vries</li>
          <br></br>
          <br></br>2023
          <br></br>
          <br></br>
          <li>Dag van de Wetenschap druivenstreek - een samenwerking van Steamwork, VUB en We-IT</li>
          <li>Bewerken van genen van gewassen of dieren. Hoe werkt dat? Is dat gevaarlijk?, door Serge Muyldermans </li>
          <li>Artificiële Intelligentie, offshore windparken en de integratie met waterstofproductie installaties, door Stijn Ally</li>
          <li>Muziek van Edison tot Stromae, een trillende reis door de geschiedenis van geluidsopnames, door Ben Vanachter</li>
          <li>Lichaamstaal, door Daan Vandervelde, Krijn de Vries, Sophie Verbruggen & Willem Van Geyseghem</li>
          <li>Speurtocht naar de bouwstenen van het heelal - deeltjesfysica, door Nick van Eijndhoven</li>
          <li>Kosmische straling vangen in Groenland, door Krijn de Vries</li>
          <li>Analoge systemen en hoe maak je een equalizer, door Jonas Verbruggen</li>
          <li>Valpartijen bij ouderen: is preventie mogelijk?, door Koen Milisen</li>
          <li>3D tekenen en printen, door Daan Vandervelde</li>
          <li>Sociale media en hun algoritmes, door Ike Picone</li>
          <li>Marketing op sociale media, door Jan Kunnen</li>
          <br></br>
          <br></br>2022
          <br></br>
          <br></br>
          <li>Introductie heelkundige operaties, door Jeroen De Man</li>
          <li>
            Serious games over samenwerken en effectiviteit, door Willem Van Geyseghem
          </li>
          <li>
          Vrouwen in de wetenschap, door Daan Vandervelde, Krijn Devries, Parmjeet Gurmeet & Willem Van
            Geyseghem
          </li>
          <li>
            Emissies en emissiereductie in de druivenstreek, door Herman Baeyens
          </li>
          <li>
            Ons universum, deeltjesfysica en astrofotografie, door Krijn de Vries, Yarno Merckx en Karel Rowies
          </li>
          <li>
            Spraaherkenning voor mensen met een spraakgebrek, door Erich Reiter (SAY It Labs)
          </li>
          <li>Slaap, door Aisha Cortoos (Brainsewise)</li>
          <li>
            Het klimaat, de drijvers en oorzaken van klimaatverandering, door Inne Vanderkelen
          </li>
          <li>
            Gender, of hoe groepsdynamiek gender conformisme vormt onder adolescenten, door Laora Mastari
            </li>
          <li>Arduino introductie, door Daan Vandervelde</li>
          <li>Legocompetitie teambuilding, door Sophie Verbruggen & Jan Kunnen - </li>
          <li>
            Hoe wordt machine learning gebruikt voor de AI van een personal assistent (zoals Siri), door Vincent Renkens
          </li>
          <br></br>2021
          <br></br>
          <br></br>
          <li>Bezoek VUB Brubotics lab, door Bram Vanderborght</li>
          <li>Over weerkunde, door Sophie & Jonas Verbruggen</li>
          <li>Crypto currencies, door Tom Poppe</li>
          <li>Workshop met OZO bots, door Sophie Verbruggen & Jan Kunnen</li>
          <li>Self-determination theorie, door Jeroen De Man</li>
          <li>Web development met React en NodeJS, door Aaron Munsters</li>
          <li>De uitwerpselen van de wombat, door Daan Vandervelde</li>
          <li>De werking van vaccins, door Pieter Muyldermans</li>
          <br></br>2020
          <br></br>
          <br></br>
          <li>We-IT onderzoek over Python App development voor Android</li>
          <li>
            Kinderprogrammeertalen & programmeren met blokken (code.org), door Jan Kunnen
          </li>
          <li>De grote online We-IT Quiz </li>
          <li>De leegheid van de ruimte, door Krijn de Vries</li>
          <li>
            Het effect van melk op spierrecuperatie, door Sophie Verbruggen
          </li>
          <li>Leiderschap en motivatie, door Willem Van Geyseghem</li>
          <li>Ruimterakketen, door Karel Rowies</li>
          <li>Donkere materie, door Jan Kunnen</li>
          <li>Ingenieuze technieken in ziekenhuizen, door Daan Vandervelde</li>
          <li>Digitale onderwijstools, door Jan Kunnen</li>
        </p>
      </box>
    </div>
  );
}

export default Missed;
